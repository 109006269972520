import { get_json, post_json } from '../fetch';
import settings from '../settings';

export interface SpinGameSection {
    points: number;
    tier: number;
}

export interface SpinGameConfig {
    sections: SpinGameSection[];
    spins: number;
}

export interface SpinGameSession {
    index: number;
    section: SpinGameSection;
    balance: number;
    spins: number;
}

class SpinGameService {
    async get_config(access_token: string): Promise<SpinGameConfig | null> {
        try {
            const response = await get_json(`${settings.wow_server_url}/games/spin/config`, access_token);
            // console.log(response);
            if (response?.sections) {
                return { sections: response.sections, spins: response.spins || 0 };
            }
        } catch (err) {
            console.error('failed to get spin game config', err);
        }
        return null;
    }
    async get_session(access_token: string): Promise<SpinGameSession | null> {
        try {
            const response = await post_json(`${settings.wow_server_url}/games/spin/session`, {}, access_token);
            //console.log(response);
            if (response?.success) {
                return {
                    index: response.index,
                    section: response.section,
                    balance: response.balance,
                    spins: response.spins,
                };
            }
        } catch (err) {
            console.error('failed to get spin game session', err);
        }
        return null;
    }
}

const spin_game_service = new SpinGameService();
export default spin_game_service;
