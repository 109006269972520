import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RegisterDialog } from './dialogs/register_dialog';
import { LoginDialog } from './dialogs/login_dialog';
import { WheelDialog } from './dialogs/wheel_dialog';

export const ActionController: FC = () => {
    const [params] = useSearchParams();

    return (
        <>
            {params.get('action') === 'login' && <LoginDialog />}
            {params.get('action') === 'register' && <RegisterDialog />}
            {params.get('action') === 'lucky-wheel' && <WheelDialog />}
        </>
    );
};
