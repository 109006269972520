import { FC, useCallback, useEffect, useState } from 'react';
import { useUserContext } from '../../contexts/user_context';
import spin_game_service, { SpinGameConfig, SpinGameSession } from '../../services/spin_game_service';
import { WheelComponent } from './wheel_component';
import { useAppContext } from '../../contexts/app_context';
import { useNavigate } from 'react-router-dom';

const TIER_COLOR = [
    '#ba4d4e',
    '#1592e8',
    '#14c187',
    '#fc7800',
    '#14c187',
    '#1592e8',
    '#ba4d4e',
    '#1592e8',
    '#14c187',
    '#fc7800',
    '#14c187',
    '#1592e8',
];

const S = 3;
const M = 3;

export const SpinGame: FC = () => {
    const { username, balance, spins, access_token, update_balance, update_spins } = useUserContext();
    const [config, set_config] = useState<SpinGameConfig | null>(null);
    const [session, set_session] = useState<SpinGameSession | null>(null);
    const [rotation, set_rotation] = useState(0);
    const [ready, set_ready] = useState(false);

    const { cancel_action } = useAppContext();

    const navigate = useNavigate();

    const fetch_config = useCallback(async () => {
        if (!access_token) return;
        const config = await spin_game_service.get_config(access_token);
        if (config) {
            set_config(config);
            update_spins(config.spins);
        }
    }, [access_token, update_spins]);

    useEffect(() => {
        fetch_config();
    }, [fetch_config]);

    useEffect(() => {
        return () => {
            if (session) {
                update_balance(session.balance);
            }
        };
    }, [session, update_balance]);

    const on_continue = useCallback(() => {
        if (!session) return;
        update_balance(session.balance);
        set_session(null);
        set_ready(false);
    }, [session, update_balance]);

    const on_spin = useCallback(async () => {
        update_spins(spins - 1);
        const session = await spin_game_service.get_session(access_token);
        if (session) {
            set_session(session);
            const total_sections = config!.sections.length;
            const slice_angle = 360 / total_sections;
            const chosen_slice_center = session.index * slice_angle + slice_angle / 2;
            set_rotation(Math.floor(rotation / 360) * 360 + (M * 360 - chosen_slice_center));
            setTimeout(() => {
                set_ready(true);
                if (!session.section.points) {
                    on_continue();
                }
            }, S * 1000);
        }
    }, [access_token, config, rotation, spins, on_continue, update_spins]);

    const on_get_spins = () => {
        cancel_action();
        navigate('/quests')
    };

    return (
        <div className='vertical-center spin-game'>
            <div className='game-info-container'>
                <div className='counter'>
                    <span>Spins:</span> {spins}
                </div>
                <div className='user-info'>
                    <span>
                        {username}
                        {' : '}
                    </span>
                    {balance}
                    {'$'}
                    {session && ready && session.section.points ? `+${session.section.points}$` : ''}
                </div>
            </div>
            {config && (
                <div className='wheel-container'>
                    <div
                        style={{
                            position: 'relative',
                            width: 220,
                            height: 220,
                            opacity: spins > 0 ? 1 : 0.2,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: `transform ${S}s cubic-bezier(0.32, 0.64, 0.45, 1)`,
                                transform: `rotate(${rotation}deg)`,
                            }}
                        >
                            <WheelComponent
                                sections={config.sections.map((section) => ({
                                    text: section.points.toString(),
                                    color: TIER_COLOR[section.tier],
                                }))}
                                width={220}
                                height={220}
                                radius={110}
                                rotation={0}
                            />
                        </div>
                    </div>
                    {spins <= 0 && (
                        <div className='wheel-earn-spins-text'>
                            <div>Get free spins on a new quest!</div>
                            <button onClick={on_get_spins} className='button primary-button'>
                                Earn spins! <div className='glow' />
                            </button>
                        </div>
                    )}
                    {!session && spins > 0 && <button onClick={on_spin}>Spin</button>}
                    {session && ready && spins > 0 && <button onClick={on_continue}>Continue</button>}
                </div>
            )}
        </div>
    );
};
