import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './games_gallery.css';

interface CardProps {
    cover?: string;
    title: string;
    game?: string;
    disabled?: boolean;
}

const GameCard: FC<CardProps> = ({ cover, game, title, disabled }) => {
    const navigate = useNavigate();

    const handle_navigate = (game?: string) => {
        if (!game) return;
        navigate(`/games/${game}`);
    };

    return (
        <div className='game-card' onClick={() => (disabled ? null : handle_navigate(game))}>
            {!disabled && cover && <img src={cover} alt='' />}
            <div className={'game-name'}>{title}</div>
            {!disabled && <button className='primary-button'>Play</button>}
        </div>
    );
};

export const GAMES_CARDS_INFO: CardProps[] = [
    {
        title: 'Dices',
        cover: '/assets/game_banner_0.png',
        game: 'dices',
    },
    { title: 'Roulette', cover: '/assets/game_banner_1.png', game: 'roulette' },
    { title: 'Coming soon...', disabled: true },
];

export const GamesGallery: FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className='games-page'>
                <div className='banner banner-lg' onClick={() => navigate('/quests')}>
                    <div className='banner-text'>New claimr quest!</div>
                    <button className='primary-button'>Play now!</button>
                </div>
                <h2>Games</h2>
                <div className='game-category'>
                    {GAMES_CARDS_INFO.map((item, index) => (
                        <GameCard
                            title={item.title}
                            cover={item.cover}
                            game={item.game}
                            disabled={item.disabled}
                            key={index}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
