import { FC, useEffect, useState } from 'react';
import { useLoaderContext } from '../../contexts/loader_context';

import loader from '../../assets/loader.gif';

import './loader.css';

export const Loader: FC = () => {
    const [show_loader, set_show_loader] = useState(true);
    const { loading, ready } = useLoaderContext();

    useEffect(() => {
        set_show_loader(true);
        if (ready && !loading) {
            setTimeout(() => {
                set_show_loader(false);
            }, 1000);
        }
    }, [loading, ready]);

    return show_loader ? (
        <div className={`loader-container `}>
            <div className='loader-progress'>
                <img src={loader} alt='' />
            </div>
        </div>
    ) : null;
};
