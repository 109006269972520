import { FC, ReactNode, createContext, useContext, useState, useEffect, useCallback } from 'react';
import storage from '../storage';
import { get_json, post_json } from '../fetch';
import settings from '../settings';

interface UserContextType {
    email: string;
    username: string;
    balance: number;
    spins: number;
    xp: number;
    xp_mul: number;
    rank: number;
    access_token: string;
    user_token: string;
    register: (email: string, username: string, password: string) => Promise<boolean>;
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => void;
    fetch_user_token: () => Promise<boolean>;
    update_quest_info: (xp: number, xp_mul: number, rank: number) => void;
    update_balance: (balance: number) => void;
    update_spins: (spins: number) => void;
    set_spins: (spins: number) => void;
}

export const UserContext = createContext<UserContextType>({
    email: '',
    username: '',
    balance: 0,
    spins: 0,
    xp: 0,
    xp_mul: 1,
    rank: 0,
    access_token: '',
    user_token: '',
    register: async (email: string, username: string, password: string) => false,
    login: async (email: string, password: string) => false,
    logout: () => {},
    fetch_user_token: async () => false,
    update_quest_info: (xp: number, xp_mul: number, rank: number) => {},
    update_balance: (balance: number) => {},
    update_spins: (spins: number) => {},
    set_spins: () => {},
});

export const UserProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const [access_token, set_access_token] = useState('');
    const [email, set_email] = useState('');
    const [username, set_username] = useState('');
    const [balance, set_balance] = useState(0);
    const [spins, set_spins] = useState(0);
    const [xp, set_xp] = useState(0);
    const [xp_mul, set_xp_mul] = useState(0);
    const [rank, set_rank] = useState(0);
    const [user_token, set_user_token] = useState('');

    const register = useCallback(async (email: string, username: string, password: string) => {
        try {
            const response = await post_json(`${settings.wow_server_url}/auth/register`, {
                email,
                username,
                password,
            });
            if (response?.token) {
                storage.set('wow-access-token', response.token);
                set_access_token(response.token);
                set_email(email);
                set_username(username);
                set_balance(response.balance || 0);
                set_spins(response.spins || 0);
                set_xp(response.xp || 0);
                set_xp_mul(response.xp_mul || 1);
                set_rank(response.rank || 0);
                set_user_token(response.user_token || '');
                return true;
            }
        } catch (err) {
            console.error('failed to register user', err);
        }
        return false;
    }, []);

    const login = useCallback(async (email: string, password: string) => {
        try {
            const response = await post_json(`${settings.wow_server_url}/auth/login`, {
                email,
                password,
            });
            if (response?.token) {
                storage.set('wow-access-token', response.token);
                set_access_token(response.token);
                set_email(email);
                set_username(response.username);
                set_balance(response.balance || 0);
                set_spins(response.spins || 0);
                set_xp(response.xp || 0);
                set_xp_mul(response.xp_mul || 1);
                set_rank(response.rank || 0);
                set_user_token(response.user_token || '');
                return true;
            }
        } catch (err) {
            console.error('failed to register user', err);
        }
        return false;
    }, []);

    const get_profile = useCallback(async () => {
        if (!access_token) return false;
        try {
            const response = await get_json(`${settings.wow_server_url}/auth/profile`, access_token);
            if (response?.email) {
                set_email(response.email);
                set_username(response.username);
                set_balance(response.balance || 0);
                set_spins(response.spins || 0);
                set_xp(response.xp || 0);
                set_xp_mul(response.xp_mul || 1);
                set_rank(response.rank || 0);
                set_user_token(response.user_token || '');
                return true;
            }
        } catch (err) {
            console.error('failed to register user', err);
        }
        return false;
    }, [access_token]);

    const update_quest_info = useCallback((xp: number, xp_mul: number, rank: number) => {
        set_xp(xp);
        set_xp_mul(xp_mul);
        set_rank(rank);
    }, []);

    const update_balance = useCallback((balance: number) => {
        set_balance(balance);
    }, []);

    const update_spins = useCallback((spins: number) => {
        set_spins(spins);
    }, []);

    const logout = useCallback(() => {
        storage.remove('wow-access-token');
        set_access_token('');
        set_email('');
        set_username('');
        set_balance(0);
        set_spins(0);
        set_xp(0);
        set_xp_mul(1);
        set_rank(0);
        set_user_token('');
    }, []);

    const fetch_user_token = useCallback(async () => {
        if (!access_token) return false;
        try {
            const response = await get_json(`${settings.wow_server_url}/claimr/token`, access_token);
            if (response?.token) {
                // console.log(response);
                set_xp(Number(response.xp));
                set_xp_mul(Number(response.xp_mul));
                set_rank(Number(response.rank));
                set_user_token(response.user_token);
                return true;
            }
        } catch (err) {
            console.error('failed to get user token', err);
        }
        return false;
    }, [access_token]);

    useEffect(() => {
        set_access_token(storage.get('wow-access-token') || '');
        const handle = (event: any) => {
            if (event.key === 'wow-access-token' && event.newValue !== access_token) {
                set_access_token(event.newValue || '');
                if (!event.newValue) {
                    logout();
                }
            }
        };
        window.addEventListener('storage', handle);
        return () => {
            document.removeEventListener('storage', handle);
        };
    }, [access_token, logout]);

    useEffect(() => {
        get_profile();
    }, [get_profile]);

    return (
        <UserContext.Provider
            value={{
                access_token,
                email,
                username,
                balance,
                spins,
                xp,
                xp_mul,
                rank,
                user_token,
                register,
                login,
                logout,
                update_quest_info,
                update_balance,
                update_spins,
                fetch_user_token,
                set_spins,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = (): UserContextType => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};
