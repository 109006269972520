import { FC } from 'react';
import { useAppContext } from '../../contexts/app_context';
import { Dialog } from './dialog';
import { SpinGame } from '../games/spin_game';

import './wheel_dialog.css';

export const WheelDialog: FC = () => {
    const { cancel_action } = useAppContext();

    return (
        <div className='wheel-dialog'>
            <Dialog title='Test your luck!' onClose={cancel_action}>
                <h2>
                    <span>FORTUNE</span> WHEEL!
                </h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <SpinGame />
            </Dialog>
        </div>
    );
};
