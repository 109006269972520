import { FC } from 'react';
import { Header } from './header';
import { ActionController } from './action_controller_component';
import { Outlet } from 'react-router-dom';
import { Footer } from './footer';
import { AppProvider } from '../contexts/app_context';
import { CombineProviders } from '../contexts/combine_providers';
import { UserProvider } from '../contexts/user_context';
import { LoaderProvider } from '../contexts/loader_context';
import { Loader } from './common/loader';

const providers = [AppProvider, UserProvider, LoaderProvider];

export const AppLayoutComponent: FC = () => {
    return (
        <CombineProviders components={providers}>
            <div className='page-container'>
                <Header />
                <main>
                    <Outlet />
                    <Loader />
                </main>
                <ActionController />
                <Footer />
            </div>
        </CombineProviders>
    );
};
