import { FC, ReactNode, createContext, useContext, useState } from 'react';

interface LoaderContextType {
    loading: boolean;
    ready: boolean;
    set_ready: (ready: boolean) => void;
    set_loading: (loading: boolean) => void;
}

export const LoaderContext = createContext<LoaderContextType>({
    loading: false,
    set_loading: () => {},
    ready: true,
    set_ready: () => {},
});

export const LoaderProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const [loading, set_loading] = useState<boolean>(false);
    const [ready, set_ready] = useState<boolean>(true);

    return (
        <LoaderContext.Provider value={{ loading, set_loading, ready, set_ready }}>
            {children}
        </LoaderContext.Provider>
    );
};

export const useLoaderContext = (): LoaderContextType => {
    const context = useContext(LoaderContext);
    if (context === undefined) {
        throw new Error('useLoaderContext must be used within a LoaderProvider');
    }
    return context;
};
