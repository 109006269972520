import { FC } from 'react';
// import { MaterialIcon } from '../common/material_icon';
import { useNavigate } from 'react-router-dom';
import { GAMES_CARDS_INFO } from './games_gallery';

interface CardProps {
    cover?: string;
    title: string;
    game?: string;
    disabled?: boolean;
}

export const GameMiniCard: FC<CardProps> = ({ cover, game, title, disabled }) => {
    const navigate = useNavigate();

    const handle_navigate = (game?: string) => {
        if (!game) return;
        navigate(`/games/${game}`);
    };

    return (
        <div className='game-mini-card' onClick={() => (disabled ? null : handle_navigate(game))}>
            {!disabled && cover && <img src={cover} alt='' />}
            <div className={'game-name'}>{title}</div>
            {/* {!disabled && (
                <button className='primary-button'>
                    <MaterialIcon icon='play_arrow' />
                </button>
            )} */}
        </div>
    );
};

interface Props {
    filter: string[];
}

export const GamesMiniGallery: FC<Props> = ({ filter }) => {
    return (
        <div className='game-category'>
            {GAMES_CARDS_INFO.filter((item) => !filter.includes(item.game || '')).map((item) => (
                <GameMiniCard title={item.title} cover={item.cover} game={item.game} disabled={item.disabled} />
            ))}
        </div>
    );
};
