import { JSXElementConstructor, PropsWithChildren, ReactNode } from 'react';

interface Props {
    components: Array<JSXElementConstructor<PropsWithChildren<unknown>>>;
    children?: ReactNode;
}

export function CombineProviders(props: Props): JSX.Element {
    const { components = [], children } = props;

    return (
        <>
            {components.reduceRight((acc, Provider) => {
                return <Provider>{acc}</Provider>;
            }, children)}
        </>
    );
}
