import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MaterialIcon } from './material_icon';
import './menu.css';

export interface MenuItem {
    path: string;
    label: string;
    icon: string;
}

interface Props {
    items: MenuItem[];
}

export const Menu: FC<Props> = ({ items }) => {
    const location = useLocation();

    return (
        <menu>
            {items.map(({ path, label, icon }, index) => (
                <Link
                    key={index}
                    data-is-active={String(Number(location.pathname === path))}
                    to={path}
                >
                    <MaterialIcon icon={icon} />
                    <span>{label}</span>
                </Link>
            ))}
        </menu>
    );
};
