import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/app_context';
import { useUserContext } from '../../contexts/user_context';

import './main_page.css';

export const MainPage: FC = () => {
    const navigate = useNavigate();
    const { access_token } = useUserContext();
    const { open_wheel_game_dialog, open_login_dialog } = useAppContext();

    return (
        <div className='main-page'>
            <div className='banner banner-lg fog fog-right' onClick={() => navigate('/quests')}>
                <div className='banner-text'>New claimr quest!</div>
                <button className='primary-button'>
                    Claimr rewards! <div className='glow' />
                </button>
            </div>
            <div
                className='banner banner-sm fog fog-top-right'
                onClick={() => (access_token ? open_wheel_game_dialog() : open_login_dialog())}
            >
                <div className='banner-text '>Test your luck!</div>
                <button className='primary-button'>
                    {access_token ? 'Spin the wheel!' : 'Login to start'}
                    <div className='glow' />
                </button>
            </div>

            <div className='banner banner-sm fog fog-left' onClick={() => navigate('/games')}>
                <div className='banner-text'>Play the best games of the season!</div>
                <button className='primary-button'>
                    Play now!
                    <div className='glow' />
                </button>
            </div>
        </div>
    );
};
