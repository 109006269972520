import { FC, ReactNode, createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export enum ScreenType {
    mobile_portrait = 'mobile_portrait',
    mobile_landscape = 'mobile_landscape',
    tablet = 'tablet',
    pc = 'pc',
}

interface AppContextType {
    screen_type: ScreenType;
    frame_width: number;
    frame_height: number;
    open_wheel_game_dialog: () => void;
    open_login_dialog: () => void;
    open_register_dialog: () => void;
    cancel_action: () => void;
}

export const AppContext = createContext<AppContextType>({
    screen_type: ScreenType.pc,
    frame_width: 0,
    frame_height: 0,
    open_wheel_game_dialog: () => {},
    open_login_dialog: () => {},
    open_register_dialog: () => {},
    cancel_action: () => {},
});

const get_screen_type = () => {
    if (window.innerWidth < 479) {
        return ScreenType.mobile_portrait;
    } else if (window.innerWidth < 767) {
        return ScreenType.mobile_landscape;
    } else if (window.innerWidth > 1100) {
        return ScreenType.pc;
    } else {
        return ScreenType.tablet;
    }
};

export const AppProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const [params, set_params] = useSearchParams();
    const [frame_width, set_frame_width] = useState(0);
    const [frame_height, set_frame_height] = useState(0);
    const [screen_type, set_screen_type] = useState(ScreenType.pc);

    useEffect(() => {
        const resize = () => {
            set_frame_width(window.innerWidth);
            set_frame_height(window.innerHeight);
            set_screen_type(get_screen_type());
        };

        window.addEventListener('resize', resize);
        resize();

        return () => {
            document.removeEventListener('resize', resize);
        };
    }, []);

    const open_login_dialog = useCallback(() => {
        let new_params = new URLSearchParams(params);
        new_params.set('action', 'login');
        set_params(new_params);
    }, [params, set_params]);

    const open_register_dialog = useCallback(() => {
        let new_params = new URLSearchParams(params);
        new_params.set('action', 'register');
        set_params(new_params);
    }, [params, set_params]);

    const cancel_action = useCallback(() => {
        let new_params = new URLSearchParams(params);
        new_params.delete('action');
        set_params(new_params);
    }, [params, set_params]);

    const open_wheel_game_dialog = useCallback(() => {
        let new_params = new URLSearchParams(params);
        new_params.set('action', 'lucky-wheel');
        set_params(new_params);
    }, [params, set_params]);

    return (
        <AppContext.Provider
            value={{
                frame_width,
                frame_height,
                screen_type,
                open_wheel_game_dialog,
                open_login_dialog,
                open_register_dialog,
                cancel_action,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = (): AppContextType => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within a AppProvider');
    }
    return context;
};
