import { FC, useEffect } from 'react';
import { CLAIMR_CONTAINER_ID } from '../../constants';
import { ClaimrWrapper } from '../claimr_wrapper';
import { useUserContext } from '../../contexts/user_context';
import { useLoaderContext } from '../../contexts/loader_context';
import './quests_page.css';

export const QuestsPage: FC = () => {
    const { user_token, access_token, fetch_user_token } = useUserContext();
    const { set_loading, set_ready } = useLoaderContext();

    useEffect(() => {
        if (!user_token) {
            fetch_user_token();
        }
    }, [user_token, access_token, fetch_user_token, set_loading]);

    useEffect(() => {
        set_loading(true);
        set_ready(false);

        const process_message = (e: MessageEvent<any>) => {
            if (e.data.event === 'claimr::ready' || window.claimr?.is_claimr_ready) {
                set_ready(true);
                set_loading(false);
            }
        };
        window.addEventListener('message', process_message);

        return () => {
            window.removeEventListener('message', process_message);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='quests-page'>
                {user_token && (
                    <ClaimrWrapper user_token={user_token}>
                        <div className='claimr-container'>
                            <div id={CLAIMR_CONTAINER_ID} />
                        </div>
                    </ClaimrWrapper>
                )}
            </div>
        </>
    );
};
