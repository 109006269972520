import '@rainbow-me/rainbowkit/styles.css';

import { FC } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';

import { config } from './wagmi';
import { AppRouting } from './app_routing';

const client = new QueryClient();

export const ExampleApp: FC = () => {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={client}>
                <RainbowKitProvider>
                    <AppRouting />
                </RainbowKitProvider>
            </QueryClientProvider>
        </WagmiProvider>
    );
};

export default ExampleApp;
